import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from ".";
import Layout from "../components/layout";
import { Wrapper } from "../components/styles";

const Application = () => {
  const data = useStaticQuery(graphql`
    query applicationPageQuery {
      allGoogleSheet {
        edges {
          node {
            application {
              title
              heroImage
            }
          }
        }
      }
    }
  `);

  const pageData = data?.allGoogleSheet?.edges?.[0]?.node?.application?.[0];

  return (
    <Layout>
      <FullScreenImage imageUrl={pageData?.heroImage}>
        <HeadlineCopy>
          <HeadlineWrapper>
            <h1>{pageData?.title}</h1>
          </HeadlineWrapper>
        </HeadlineCopy>
      </FullScreenImage>
      <Wrapper>
        <>Content</>
      </Wrapper>
    </Layout>
  );
};

export default Application;
